<template>
  <el-dialog :title="roleRow.id ? '编辑': '新增'" :visible.sync="roleAddOrEditVisible" :close-on-click-modal="false" width="600px" append-to-body :before-close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="角色名称" prop="roleName">
        <el-input clearable class="w230" placeholder="请输入角色名称" v-model="ruleForm.roleName"></el-input>
      </el-form-item>
      <el-form-item label="角色描述"  prop="describes">
        <el-input clearable class="w230" placeholder="请输入角色描述" v-model="ruleForm.describes"></el-input>
      </el-form-item>
      <el-form-item label="是否为志愿者部门" prop="weatherVolunteerRole">
        <el-select v-model="ruleForm.weatherVolunteerRole" placeholder="请选择">
          <el-option label="不是" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addOrEditRole } from "@/api/roleManagement"
  export default {
    name: "addOrEditDilog",
    props: {
      roleAddOrEditVisible: {
        type: Boolean,
        default: false
      },
      roleRow: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        ruleForm: {
          describes: '',
          roleName: '',
          weatherVolunteerRole: 0
        },
        rules: {
          describes: [{ required: true, message: '请输入角色描述', trigger: 'blur' }],
          roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
          weatherVolunteerRole: [
            { required: true, message: '请选择', trigger: 'change' }
          ],
        }
      }
    },
    mounted() {
       this.ruleForm = this.roleRow
    },
    methods: {
      handleClose() {
        this.resetForm('ruleForm')
        this.$emit('update:roleAddOrEditVisible', false)
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = JSON.parse(JSON.stringify(this.ruleForm))
            this.addOrEditRolePost(params)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      async addOrEditRolePost(params) {
        const res = await addOrEditRole(params)
        if(res.state === 0) {
          this.$message.success(res.data)
          this.$emit('updateList')
        }
        this.$emit('update:roleAddOrEditVisible', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w230 {
    width: 300px;
  }
  .info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
  }
</style>
