<template>
  <el-card class="box-card">
    <div class="search-table-box">
      <div>
        <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
          <el-form-item label="关键字">
            <el-input
              style="width: 300px"
              clearable
              v-model="queryParams.keyWord"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
<!--          <el-form-item label="角色性质">
            <el-select class="w300" v-model="queryParams.institutionalNature" placeholder="请选择机构性质" clearable>
              <el-option
                v-for="item in roleListConfig"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="searchList">查询</el-button>
          </el-form-item>
          <el-button style="float: right" type="primary" @click="addOrEditRole">新增角色</el-button>
        </el-form>

      </div>
      <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border :header-cell-style="headerCellStyle">
        <template slot="empty">
          <IsEmpty />
        </template>
        <el-table-column type="index" label="序号" width="50" align="center" />
        <el-table-column prop="roleName" label="角色名称" align="center" />
        <el-table-column prop="describes" label="角色描述" show-overflow-tooltip align="center" />
        <el-table-column prop="pop" label="操作" width="400" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
            <el-button type="text" v-if="userInfo.roleId !== scope.row.id" @click="addOrEditRole(scope.row)">编辑</el-button>
            <el-button type="text" v-if="userInfo.roleId !== scope.row.id" @click="setPermisson(scope.row)">设置权限</el-button>
            <el-button class="del" v-if="userInfo.roleId !== scope.row.id" type="text" @click="delRole(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <addOrEditDlog v-if="roleAddOrEditVisible" @updateList="getList" :roleRow="roleRow" :roleAddOrEditVisible.sync="roleAddOrEditVisible"/>
      <setPermissonDilog v-if="permissionVisible" @updateList="getList" :isPermissionState="isPermissionState" :roleId="roleId" :permissionVisible.sync="permissionVisible"/>
      <roleDetailDilog v-if="roleDetailVisible" :detailData="detailData" :roleDetailVisible.sync="roleDetailVisible"/>
    </div>
  </el-card>
</template>

<script>
  import { getRoleList, deleteRole } from "@/api/roleManagement"
  import addOrEditDlog from "./components/addOrEditDlog";
  import setPermissonDilog from "./components/setPermissonDilog";
  import roleDetailDilog from "./components/roleDetailDilog";
  export default {
    name: 'index',
    components: {
      addOrEditDlog,
      setPermissonDilog,
      roleDetailDilog
    },
    data() {
      return {
        detailData: {},
        roleDetailVisible: false,
        isPermissionState: '',
        roleId: '',
        permissionVisible: false,
        roleRow: {},
        roleAddOrEditVisible: false,
        tableData: [],
        total: 0,
        loading: false,
        roleListConfig: [
          {label: '系统默认', value: 1},
          {label: '自定义', value:2}
        ],
        userInfo: {},
        queryParams: {
          //查询参数
          pageNum: 1,
          pageSize: 10,
          keyWord: ''
        }
      }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
      this.getList()
    },
    methods: {
      toDetail(row) {
        this.detailData = row
        this.roleDetailVisible = true
      },
      change(value) {
        console.log(value, 'change')
      },
    //分页序号
      count(index) {
        return (this.queryParams.pageNum - 1) * this.queryParams.pageSize + index + 1
      },
      searchList() {
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleSizeChange(pageSize) {
        this.queryParams.pageSize = pageSize
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleCurrentChange(pageNum) {
        this.queryParams.pageNum = pageNum
        this.getList()
      },
     async getList() {
        this.loading = true
        const res = await getRoleList(this.queryParams)
       if(res.state === 0) {
        this.tableData = res.data.rows
         this.total = res.data.total
       }
       this.loading = false
      },
      /*新增、编辑*/
      addOrEditRole(row) {
        if(row && row.id) {
          this.roleRow = row
        }else {
          this.roleRow =  {
            describes: '',
              roleName: ''
          }
        }
        this.roleAddOrEditVisible = true
      },
      /*删除*/
     async delRole(row) {
       this.$confirm(`此操作将删除"${row.roleName}"角色, 是否继续?`, '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning'
       }).then(() => {
         deleteRole({id: row.id}).then(res => {
           if(res.state === 0) {
             this.$message.success(res.data)
             this.getList()
           }
         })

       }).catch(() => {
         this.$message({
           type: 'info',
           message: '已取消删除'
         });
       });
      },
      setPermisson(row) {
       this.permissionVisible = true
        this.roleId = row.id
        this.isPermissionState = row.state
      }
    },
  }
</script>

<style lang="scss" scoped>
  .box-card {
    min-height: calc(100% - 10px);
    .btn {
      text-align: right;
    }
    .del {
      color: rgb(255, 90, 89);
    }
  }
</style>
