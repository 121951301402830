<template>
  <el-dialog title="设置权限" :visible.sync="permissionVisible" :close-on-click-modal="false" width="600px" append-to-body :before-close="handleClose">
    <div class="tree-box">
<!--      {{leftsceneNum}}/{{checkedIDS.length}}-->
      <div class="topNum">
        <span>应用场景({{leftsceneNum}}) </span> /
        <span> 菜单({{rightIdsNum}})</span>
        <el-tooltip class="item" effect="dark" content="橙色为应用场景，绿色为被勾选中的菜单" placement="top">
          <span><i class="el-icon-question"></i></span>
        </el-tooltip>

      </div>
      <el-tree
        :data="treeData"
        show-checkbox
        node-key="id"
        ref="tree"
        default-expand-all
        :default-checked-keys="checkedIDS"
        @check="getCheck"
        :props="defaultProps">
      </el-tree>
    </div>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getMenuTree, setPermissions } from "@/api/roleManagement"
  import { setUpMenuTree } from "@/api/common"
  export default {
    name: "setPermissonDilog",
    props: {
      permissionVisible: {
        type: Boolean,
        default: false
      },
      isPermissionState: {
        type: [String, Number],
        default: ''
      },
      roleId: {
        type: [String, Number],
        default: ''
      }
    },
    data() {
      return {
        leftsceneNum: 0,
        rightIdsNum: 0,
        treeData: [],
        checkedIDS: [], //选中的ID
        defaultProps: {
          label: 'title'
        },
        enParams: []
      }
    },
    mounted() {
      this.getMenuTreeData()
     this.$nextTick(() => {

     })
    },
    methods: {
      /*编辑获取回显数据*/
     async postSelctedTree() {
       let userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
       let params = {
         isPrimaryAccount: userInfo.isPrimaryAccount,
         userName: userInfo.username,
         roleId: this.roleId,
         unitId: userInfo.unitId
       }
       let res = await setUpMenuTree(params)
       let treeData = res.data
       this.recomData(treeData) //id修改为 场景id + 菜单id
       /*获取选中的ID*/
       this.checkedIDS = this.getCheckedIds(treeData)
       /*获取半选的Id*/
       let halfCheckedIds = this.gethalfCheckedIds(treeData)
       /*半选加全选ID 用来获取初始化不进行任何操作传给后端的数据*/
       let allCheckedIds = this.checkedIDS.concat(halfCheckedIds)
       /*右侧选中的数据*/
       this.rightIdsNum = this.checkedIDS.length
       //初始化计算选中场景数量 半选+全选
       this.leftsceneNum = this.getTopTotalNum(this.checkedIDS)
       /*获取选中和未选中组成的参数,初始化不进行任何选择*/
       this.enParams = this.getParams(treeData, this.checkedIDS, allCheckedIds)
       /*初始化获取选中的菜单数量 半选+全选*/
       this.initCheckoutTree()
      },
      getCheck(currentNode,checkNode) {
        /*选中的ID*/
        let ChekcdIdS = checkNode.checkedKeys
        /*半选的id*/
        let HalfcheckedIdS = checkNode.halfCheckedKeys.concat(ChekcdIdS)
        /*计算选中菜单数量*/
        let allchecked = checkNode.checkedKeys.concat(checkNode.halfCheckedKeys)
        this.rightIdsNum = allchecked.length - this.getTopTotalNum(ChekcdIdS)

        /*回显选中*/
        this.checkedIDS = ChekcdIdS
        this.leftsceneNum = this.getTopTotalNum(checkNode.checkedKeys)
        /*获取选中和未选中组成的参数*/
        this.enParams = this.getParams(this.treeData, ChekcdIdS, HalfcheckedIdS)
      },

      getParams(treeData,idsArr, halfIdsArr) {
        let params = []
        let fn = (list, keys, halfKeys) => {
          for (let i = 0; i < list.length; i++) {
            let item = list[i]
            if(keys.includes(item.id)) {
              params.push({ checked: 1, state: halfKeys.includes(item.id) ? 1 : 0,menuId: item.noMenuId ? '' : item.id.split('-')[1]*1, scenarioId:item.scenarioId,"roleId": this.roleId})
            }else {
              params.push({ checked: 0, state: halfKeys.includes(item.id) ? 1 : 0,menuId: item.noMenuId ? '' : item.id.split('-')[1]*1, scenarioId:item.scenarioId,"roleId": this.roleId})
            }
          if(item.children && item.children.length) {
            fn(item.children, keys, halfKeys)
          }
          }
        }
        fn(treeData,idsArr, halfIdsArr)
        return params
      },
      handleClose() {
        this.$emit('update:permissionVisible', false)
      },
      submitForm() {
        this.setPermissionsPost()
      },
      async getMenuTreeData() {
        const res = await getMenuTree({roleName: JSON.parse(localStorage.getItem('cad_userInfo')).username})
        this.treeData = res.data
        this.treeData.forEach(item =>{
          item.noMenuId = true
        })
        // this.leftsceneNum = this.treeData.length
        this.recomData(this.treeData) //id修改为 场景id + 菜单id
        /*调回显的数据接口*/
        this.postSelctedTree()
        /*获取选中的ID*/
        // this.checkedIDS = this.getCheckedIds(this.treeData)
/*        /!*获取半选的Id*!/
        let halfCheckedIds = this.gethalfCheckedIds(this.treeData)
        /!*半选加全选ID 用来获取初始化不进行任何操作传给后端的数据*!/
        let allCheckedIds = this.checkedIDS.concat(halfCheckedIds)
        /!*右侧选中的数据*!/
        this.rightIdsNum = this.checkedIDS.length
        //初始化计算选中场景数量 半选+全选
        this.leftsceneNum = this.getTopTotalNum(this.checkedIDS)
        /!*获取选中和未选中组成的参数,初始化不进行任何选择*!/
         this.enParams = this.getParams(this.treeData, this.checkedIDS, allCheckedIds)
        /!*初始化获取选中的菜单数量 半选+全选*!/
        this.initCheckoutTree()*/
      },
      // 对半选和全选的进行反选
      async initCheckoutTree() {
        // 初始化需要等dom元素加载完毕以后在进行获取ref
        await this.$nextTick();
        const treeDataRef = this.$refs.tree;
        let allchecked = treeDataRef.getCheckedKeys().concat(treeDataRef.getHalfCheckedKeys())
        this.rightIdsNum = allchecked.length - this.getTopTotalNum(this.checkedIDS)
      },
      /*计算数量*/
      getTopTotalNum(list) {
        let resultNum =  Array.from(new Set(list.map(item => {
          return item.split('-')[0]
        })))
        return resultNum.length
      },
      /*重置id*/
      recomData(data) {
        let fn = (arr) => {
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i]
            item.id = item.scenarioId + '-' + item.id
            if(item.children && item.children.length) {
              fn(item.children)
            }
          }
        }
      fn(data)
      },
      /*获取选中的ID*/
      getCheckedIds(data) {
       let ids = []
       let fn = (list) => {
         for (let i = 0; i < list.length; i++) {
           let item = list[i]
           if(item.checked && item.checked === 1) {
             ids.push(item.id)
           }
           if(item.children && item.children.length) {
             fn(item.children)
           }
         }
       }
       fn(data)
        return ids
      },
      /*获取半选的ID*/
      gethalfCheckedIds(data) {
        let ids = []
        let fn = (list) => {
          for (let i = 0; i < list.length; i++) {
            let item = list[i]
            if(item.state && item.state === 1) {
              ids.push(item.id)
            }
            if(item.children && item.children.length) {
              fn(item.children)
            }
          }
        }
        fn(data)
        return ids
      },
      /*新增、编辑*/
      async setPermissionsPost() {
        const res = await setPermissions(this.enParams)
        this.$message.success(res.data)
        this.$emit('updateList')
        this.handleClose()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tree-box {
    height: 500px;
    overflow-y: auto;
  }
  .topNum {
    margin-bottom: 20px;
    span:first-child {
      color: orange;
    }
    span:nth-child(2) {
      color: #3fcf89;
      display: inline-block;
      padding-right: 10px;
    }
  }
  /deep/ .el-tree-node__content .el-checkbox.is-checked + .el-tree-node__label{
    color:#3fcf89;
  }
/*  /deep/ .el-tree-node__content .el-checkbox + .el-tree-node__label{
    color:#3fcf89;
  }*/
  /deep/ .el-tree {
    &>.el-tree-node {
      &>.el-tree-node__content {
        &>.el-tree-node__label {
          color: orange !important;
        }
        &>.el-checkbox {
          &>.el-checkbox__input {
            &>.el-checkbox__inner {
              border-color: orange !important;
            }
          }

          &>.el-checkbox__input.is-checked, .is-indeterminate {
            &>.el-checkbox__inner {
              background-color: orange !important;
            }
          }
        }
      }
    }
  }
</style>
